import React, { useState, useEffect, useMemo } from 'react'
import styles from '../index.module.scss'
import ActivitylBg from '@images/banner/activity-l.png'
import ActivityrBg from '@images/banner/activity-r.png'
import ActivityH5Bg from '@images/banner/activity-h5-bg.png'
import Image from 'next/image'
import buriedsdk from '@/utils/zz-buried/index'
import { useWidth } from '@/utils/resize'
import TimeDown from './TimeDown'
import { useTranslation } from 'next-i18next'

export default function Activity({ info, infoData }) {
  const { i18n } = useTranslation()

  const { width, display } = useWidth() //服务端
  const [appWidth, setWidth] = useState(0)
  const [timeDownVisible, setTimeDownVisible] = useState(true)

  // 应用端更新下width
  useEffect(() => {
    setWidth(width)
  }, [width])

  useEffect(() => {
    setTimeDownVisible(!!info.usable)
  }, [info.usable])

  const activityBaseUrl = useMemo(() => {
    if (i18n.language === 'vi') {
      return 'https://mcdn.bastaccount.net/custom/images/activity/v1.8/home/vi'
    }
    return 'https://mcdn.bastaccount.net/custom/images/activity/v1.8/home/en'
  }, [i18n.language])

  const handleClaim = (e) => {
    const mobileUrl = process.env.mobileUrl
    const pcUrl = process.env.portalUrl
    e.stopPropagation()
    const userInfo = sessionStorage.getItem('authorized-token')

    const actions = infoData.map((item) => {
      const itemJson = { marketing_id: item.marketingId, group_num: item.groupNum }
      return {
        event: `按钮点击`,
        scene: `定价升级活动入口-官网banner`,
        URL: process.env.publicApiDomain,
        json_str: item.marketingId ? JSON.stringify(itemJson) : null,
        user_id: JSON.parse(userInfo || '{}')?.userId
      }
    })
    buriedsdk.buried({}, actions)
    setTimeout(() => {
      window.open(
        // width > 1024 ? pcUrl + '/activity/list' : mobileUrl + '/newUserActivity',
        width > 1024 ? pcUrl + '/checkout' : mobileUrl + '/checkout',
        '_blank'
      )
    }, 500)
  }

  //倒计时结束
  const onTimeDownEnd = () => {
    setTimeDownVisible(false)
  }
  return (
    <div
      className={`${styles.main} ${styles.activity} cursor-pointer`}
      style={{ background: 'linear-gradient(120deg, #FFEEA1,#FF9FA1)' }}
      onClick={handleClaim}
    >
      <div className={`defeaultContainer ${styles.top} flex  `}>
        <div className="w-full flex lg1024:hidden justify-between items-center">
          <div className="flex flex-col ">
            {timeDownVisible && appWidth > 1023 ? (
              <div className={`flex items-center mb-[43px] ${styles['activity-expires']}`}>
                <TimeDown targetDate={info.usableEndTime} type="pc" onEnd={onTimeDownEnd} />
              </div>
            ) : null}
            <Image
              width={566}
              height={197}
              src={`${activityBaseUrl}/text-l.png`}
              alt="activity-h5-bg"
              objectFit="contain"
            />
            <div className="mt-[50px]">
              <Image
                width={297}
                height={70}
                src={`${activityBaseUrl}/btn-l.png`}
                alt="activity-h5-bg"
                objectFit="contain"
              />
            </div>

            {/* <div
              className={` flex items-center justify-center cursor-pointer ${styles['activity-btn']}`}
              onClick={handleClaim}
            >
              Learn More
            </div> */}
          </div>
          <div className="w-[50%] " onClick={handleClaim}>
            <Image
              width={750}
              height={580}
              src={`${activityBaseUrl}/bg-r.png`}
              objectFit="contain"
              alt="activity-r-bg"
            />
          </div>
        </div>
        <div className="lg1024:block w-full h-full hidden absolute top-0 ">
          <div className="absolute top-0 left-0 w-full h-full" onClick={handleClaim}>
            <Image src={`${activityBaseUrl}/h5.png`} layout="fill" alt="activity-h5-bg" />
            <div className="absolute top-[230px] left-[50%] transform -translate-x-[50%] flex flex-col">
              {appWidth <= 1024 && timeDownVisible && (
                <TimeDown
                  lessDay={7}
                  type="mobile"
                  targetDate={info.usableEndTime}
                  onEnd={onTimeDownEnd}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
